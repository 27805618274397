import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AsYouType } from 'libphonenumber-js';
import { isNumberMobileAndValid } from '../../helpers/phoneNumberHelper';
import nextLoading from '../../assets/images/next-loading.svg';
import OtpInput from 'react-otp-input';
import { firebaseAnalyticsNO } from '../../firebase';
import { eventNames } from 'configuration/eventNames';
import { ORDER_STAGE_TYPES } from 'helpers/enums';
import { BackButtonCWUI } from '@next-order/next-order-components';
import { UserLoginRegistrationContext } from 'contexts/UserLoginRegistrationProvider';
import { API_URL } from 'configuration/urls';
import { TableBookingViewContext } from 'contexts/TableBookingViewProvider';

const NO_OF_OTP_INPUTS = 6;

export default function VerificationCodeComponent({
  currentOrder,
  inLayoutFlow,
  updateOrderStageBack,
  storeConfig,
  sidebarAnimationClass,
}) {
  const {
    localState,
    setLocalState,
    updateOrderStageHandler,
    validateMobileNumber,
    handleVerificationCodeError,
  } = useContext(UserLoginRegistrationContext);
  const loginType = useContext(TableBookingViewContext)[0]?.loginType;

  const [verificationCode, setVerificationCode] = useState('');
  const [isNumberEditable, setIsNumberEditable] = useState(false);
  const [errors, setErrors] = useState({});
  const [mobileNumberEdited, setMobileNumberEdited] = useState('');
  const [displayMobileNumberEdited, setDisplayMobileNumberEdited] =
    useState('');

  const editMobileNumberRef = useRef(null);
  const otpRef = useRef(null);
  const COUNTRY_IDENTIFIER = storeConfig.countryIdentifier;

  useEffect(() => {
    if (
      currentOrder?.stage === ORDER_STAGE_TYPES.VERIFY_FROM ||
      inLayoutFlow === true
    ) {
      otpRef?.current?.focusInput?.(0);
    }
  }, [currentOrder?.stage, inLayoutFlow]);

  useEffect(() => {
    if (
      currentOrder.stage === ORDER_STAGE_TYPES.VERIFY_FROM ||
      inLayoutFlow === true
    ) {
      const firstInput = document.getElementsByClassName(
        'verification-otp-input'
      )[0];
      firstInput?.focus();
      setMobileNumberEdited(localState.displayMobileNumber);
    }
  }, [currentOrder.stage, localState.displayMobileNumber, inLayoutFlow]);

  useEffect(() => {
    if (!!localState.verificationCodeError) {
      otpRef.current.focusInput(0);
      setVerificationCode('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.verificationCodeError]);

  /////////////////////////////////////////////////////////////
  // need to remove when Newzealand NZ sms issue is resolved
  useEffect(() => {
    if (COUNTRY_IDENTIFIER?.toLowerCase() === 'nz') {
      const tempCodeForNZ = '123456';
      setVerificationCode(tempCodeForNZ);
      handleVerificationCodeNextStep(tempCodeForNZ);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [COUNTRY_IDENTIFIER]);
  // need to remove when Newzealand NZ sms issue is resolved
  /////////////////////////////////////////////////////////////

  useEffect(() => {
    let numberAsUserType = new AsYouType(COUNTRY_IDENTIFIER).input(
      mobileNumberEdited
    );
    setDisplayMobileNumberEdited(numberAsUserType);
    const { error } = isNumberMobileAndValid(
      numberAsUserType,
      COUNTRY_IDENTIFIER
    );
    setErrors({
      mobileNumberEdited: error,
    });
  }, [COUNTRY_IDENTIFIER, mobileNumberEdited]);

  useEffect(() => {
    if (isNumberEditable) {
      editMobileNumberRef.current?.focus();
    }
  }, [isNumberEditable]);

  useEffect(() => {
    if (verificationCode.length === NO_OF_OTP_INPUTS) {
      handleVerificationCodeSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  const handleMobileNumberChangeInVerification = (number) => {
    if (number !== localState.validatedNumber) {
      setLocalState({
        shouldResendCodeOnNumberEdit: true,
      });

      validateMobileNumber(number);
    }
  };

  const handleEditMobileNumber = () => {
    if (!isNumberEditable) {
      setIsNumberEditable(true);
    } else {
      setIsNumberEditable(false);
      handleMobileNumberChangeInVerification(mobileNumberEdited);
    }
  };

  const handleMobileNumberEditedOnChange = (e) => {
    setMobileNumberEdited(e.target.value);
  };

  const handleVerificationCodeNextStep = (verificationCode) => {
    if (verificationCode.length < 6) {
      handleVerificationCodeError({
        err: 'Verification Code is less than 6 digits',
        verificationCodeEntered: verificationCode,
        mobileNumber: localState.validatedNumber,
      });
    } else {
      updateOrderStageHandler(
        ORDER_STAGE_TYPES.REVIEW,
        verificationCode,
        loginType
      );
    }
  };

  const handleVerificationCodeSubmit = () => {
    firebaseAnalyticsNO.logEvent(eventNames.ENTERED_OPT);
    handleVerificationCodeNextStep(verificationCode);
  };

  const resendOTP = async (mobileNumberToResendTo) => {
    setLocalState({
      isLoading: true,
    });
    let { restaurantId, storeId } = storeConfig;
    const requestObj = {
      mobileNumber: mobileNumberToResendTo,
      restaurantId,
      storeId,
    };
    const response = await fetch(`${API_URL}api/v1/resendOTP`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestObj),
    });
    const responseData = await response.json();
    const hasErrors = responseData?.errors?.length > 0;

    setLocalState({
      isCodeResent: !hasErrors ? true : localState.isCodeResent,
      authCodeCounter: !hasErrors ? 30 : localState.authCodeCounter,
      isLoading: false,
    });
  };

  const handleResendVerificationCode = () => {
    resendOTP(localState.validatedNumber);
  };

  const handleResendButtonClick = (e) => {
    handleResendVerificationCode(e);
    const firstInput = document.getElementsByClassName(
      'verification-otp-input'
    )[0];
    firstInput?.focus();
    setVerificationCode('');
  };

  const handleChange = useCallback(
    (code) => setVerificationCode(code),
    [setVerificationCode]
  );

  return (
    <div
      className={`${sidebarAnimationClass} detailsConfirm detailMobileVer active`}
    >
      <BackButtonCWUI onClick={updateOrderStageBack} />
      <div
        className='detailsTitle hidden-xs hidden-sm'
        style={{ marginTop: 0 }}
      >
        Thanks {localState.firstName.trim()}, we just need to verify your mobile
      </div>
      <div
        className='detailsTitle visible-xs visible-sm'
        style={{ marginTop: 0 }}
      >
        We just need to verify {localState.displayMobileNumber}
      </div>
      <div className='mobVerImp'>
        <span>Verification Code</span>
        {(currentOrder.stage === ORDER_STAGE_TYPES.VERIFY_FROM ||
          inLayoutFlow === true) && (
          <>
            <OtpInput
              ref={otpRef}
              value={verificationCode}
              onChange={handleChange}
              numInputs={NO_OF_OTP_INPUTS}
              isInputNum
              shouldAutoFocus
              containerStyle='verification-code-container'
              inputStyle={'verification-otp-input text-2xl text-bold'}
              focusStyle={'verification-otp-input-focus'}
              errorStyle={'verification-otp-input-error'}
              hasErrored={!!localState.verificationCodeError}
              isDisabled={false}
              renderInput={(props) => <input {...props} />}
            />
            {!!localState.verificationCodeError && (
              <p className='text-red-600'>Wrong code. Try again.</p>
            )}
          </>
        )}
        <div
          className={`phoneEdit hidden-xs hidden-sm${
            errors['mobileNumberEdited'] ? ' error' : ''
          }`}
        >
          <input
            type='text'
            name='mobileNumberEdited'
            value={displayMobileNumberEdited}
            ref={editMobileNumberRef}
            disabled={!isNumberEditable}
            onChange={handleMobileNumberEditedOnChange}
          />
          <span
            onClick={handleEditMobileNumber}
            style={errors['mobileNumberEdited'] ? { opacity: '0.5' } : {}}
          >
            {isNumberEditable ? 'Save' : 'Edit'}
          </span>
        </div>
        {
          /////////////////////////////////////////////////////////////
          // need to remove the nz check when Newzealand NZ sms issue is resolved
          COUNTRY_IDENTIFIER?.toLowerCase() !== 'nz' && (
            <>
              <div
                className={`phoneRe active ${
                  localState.authCodeCounter ? ' disabled' : ''
                }`}
                onClick={handleResendButtonClick}
              >
                {localState.isCodeResent ? (
                  'Sent!'
                ) : (
                  <>
                    Resend <span className='visible-xs visible-sm'>code</span>
                  </>
                )}
              </div>
              {localState.authCodeCounter ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span>
                    Please wait for {localState.authCodeCounter} seconds
                  </span>
                </div>
              ) : null}
            </>
          )
          // need to remove nz check when Newzealand NZ sms issue is resolved
          /////////////////////////////////////////////////////////////
        }
      </div>
      {currentOrder.stage === ORDER_STAGE_TYPES.VERIFY_FROM && (
        <div
          style={{ marginTop: 20 }}
          className={
            verificationCode &&
            verificationCode.length === 6 &&
            !localState.isLoading
              ? // ? this.state.isNumberSubmittedAgain
                'nextDetails'
              : // : 'nextDetails wrongCode'
                'nextDetails disabled'
          }
          onClick={handleVerificationCodeSubmit}
        >
          {localState.isLoading ? <img src={nextLoading} alt='' /> : 'Next'}
          {localState.isLoading ? null : (
            <i className='thickcon-Right-Chevron' />
          )}
        </div>
      )}
    </div>
  );
}

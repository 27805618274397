import { ItemsCarouselUI, OrderUI } from '@next-order/next-order-components';
import { selectStoreConfig } from 'containers/Layout/selectors';
import useGetPopulars from 'helpers/hooks/useGetPopulars';
import React from 'react';
import { useSelector } from 'react-redux';

export default function OrderCartPreFooter({ onClickItemHandler }) {
  const statePopulars = useGetPopulars();
  const storeConfig = useSelector(selectStoreConfig);

  return statePopulars?.length > 0 ? (
    <OrderUI.Group
      extendedHeadingClassName='!tracking-s-tight'
      title='Complement your Order'
    >
      <ItemsCarouselUI extendedClassName='-mr-5 mt-4'>
        <React.Fragment key='.0'>
          {statePopulars.map((item, index) => (
            <ItemsCarouselUI.Item
              key={index}
              currency={storeConfig.currency}
              imgSrc={item.bgImgSrc}
              price={`${item.price}`?.replace(/[a-z]/gi, '')?.trim()}
              title={item.productName}
              onClick={() => onClickItemHandler(item.itemType, item.item)}
              removeSpaceAfterCurrency={true}
            />
          ))}
        </React.Fragment>
      </ItemsCarouselUI>
    </OrderUI.Group>
  ) : null;
}

import { _firestore, _auth } from '../../firebase';
import firebase from 'firebase/app';
import localStorage from 'local-storage';
import moment from 'moment-timezone';
import { getCurrentMomentDate } from '../../helpers/timeHelper';
import {
  updateMenuItems,
  updateMenuItemSchedules,
  updateOrderSetup,
  updateSoldOutMenuItems,
} from './actions';
import {
  MENU_ORDER_FROM,
  MENU_ORDER_TYPE,
  ORDER_STAGE_TYPES,
} from 'helpers/enums';
import ActionTypes from './constants';
import { getReviewStatsByRatingPromise, getReviewStatsPromise } from 'containers/Layout/methods';
import { getAllShiftsPromise, getAllSuburbsPromise, getIngredientCategoriesPromise } from 'containers/Order/methods';

function parseMenu(querySnapshot) {
  return querySnapshot.docs
    ? querySnapshot.docs.map((doc) => ({ _id: doc.id, ...doc.data() }))
    : [];
}

function resolveParseMenu(resolve) {
  return function (data) {
    resolve(parseMenu(data));
  };
}

export function getCategoriesPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('Categories')
      .where('stores', 'array-contains', storeId)
      .where('isDeleted', '==', false)
      .orderBy('order')
      .get()
      .then(resolveParseMenu(resolve));
  });
}

export function getMenuItemsPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('MenuItems')
      .where('stores', 'array-contains', storeId)
      .where('isDeleted', '==', false)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          let {
            categoryId,
            name,
            isNameHidden,
            urlS3,
            url,
            date,
            dateTimestamp,
            storeId,
            restaurantId,
            selectedExtraIngredients,
            selectedIngredients,
            selectedSizes,
            selectedModifiers,
            description,
            displayOrder,
            itemType,
            totalRevenue,
            baseModifierId,
            orderFromKeys,
            orderTypeKeys,
            isPublished,
          } = doc.data();
          return {
            _id: doc.id,
            categoryId,
            name,
            isNameHidden,
            urlS3,
            url,
            date,
            dateTimestamp,
            storeId,
            restaurantId,
            selectedExtraIngredients,
            selectedIngredients,
            selectedSizes,
            selectedModifiers,
            description,
            displayOrder,
            itemType,
            totalRevenue,
            baseModifierId,
            orderFromKeys: orderFromKeys
              ? orderFromKeys
              : [MENU_ORDER_FROM.web, MENU_ORDER_FROM.pos],
            orderTypeKeys: orderTypeKeys
              ? orderTypeKeys
              : [
                  MENU_ORDER_TYPE.walkin,
                  MENU_ORDER_TYPE.pickup,
                  MENU_ORDER_TYPE.delivery,
                  MENU_ORDER_TYPE.dinein,
                ],
            isPublished,
          };
        });
        resolve(data);
      });
  });
}

export function getMenuItemSchedulesPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('MenuItemSchedules')
      .where('storeId', '==', storeId)
      .get()
      .then((querySnapshot) => {
        let dataArray = [];
        querySnapshot?.docs?.forEach((doc) => {
          dataArray.push({ ...doc.data() });
        });
        resolve(dataArray);
      });
  });
}

export function getMenuItemSchedules(storeId) {
  return (dispatch) => {
    _firestore
      .collection('MenuItemSchedules')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot?.docs?.map((doc) => {
          return doc?.data();
        });
        dispatch(updateMenuItemSchedules(data));
      });
  };
}

export function getMenuItemSizesPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('MenuItemSizes')
      .where('storeId', '==', storeId)
      .where('orderFrom', '==', '1') // 1 - online
      .get()
      .then((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        resolve(dataArray);
      });
  });
}

export function getMenuItemSubModifiersPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('MenuItemSubModifiers')
      .where('storeId', '==', storeId)
      .where('orderFrom', '==', '1') // 1 - online
      .get()
      .then((querySnapshot) => {
        let dataArray = [];
        querySnapshot.docs.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        resolve(dataArray);
      });
  });
}

export function getAllSpecialsPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('Specials')
      .where('stores', 'array-contains', storeId)
      .where('isDeleted', '==', false)
      .get()
      .then(resolveParseMenu(resolve));
  });
}

function postProcessJsonData(key, data) {
  switch(key) {
    case 'menuItems': return data.map(item => ({
      ...item,
      orderFromKeys: item.orderFromKeys || [MENU_ORDER_FROM.web, MENU_ORDER_FROM.pos],
      orderTypeKeys: item.orderTypeKeys || [MENU_ORDER_TYPE.walkin, MENU_ORDER_TYPE.pickup, MENU_ORDER_TYPE.delivery, MENU_ORDER_TYPE.dinein]
    }))
    case 'populars': return data.map(item => ({
      ...item,
      _id: item.menuItemId,
      orderFromKeys: item.orderFromKeys || [MENU_ORDER_FROM.web, MENU_ORDER_FROM.pos],
      orderTypeKeys: item.orderTypeKeys || [MENU_ORDER_TYPE.walkin, MENU_ORDER_TYPE.pickup, MENU_ORDER_TYPE.delivery, MENU_ORDER_TYPE.dinein]
    }))
  }
  return data
}

export function getHalfHalfPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('ProductSetup')
      .where('storeId', '==', storeId)
      .get()
      .then((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        resolve(
          dataArray.length > 0
            ? dataArray[0]
            : { halfHalfs: [], halfHalfAdditionalCharges: 0 }
        );
      });
  });
}

export function getPopularsPromise(storeId, isPopularDisabled) {
  return new Promise((resolve, _reject) => {
    if (isPopularDisabled) {
      resolve([]);
    }
    _firestore
      .collection('Populars')
      .where('storeId', '==', storeId)
      .orderBy('index')
      .limit(15)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          let {
            categoryId,
            name,
            isNameHidden,
            urlS3,
            url,
            date,
            dateTimestamp,
            storeId,
            restaurantId,
            selectedExtraIngredients,
            selectedIngredients,
            selectedSizes,
            menuItemId,
            description,
            itemType,
            baseModifierId,
            selectedModifiers,
            orderFromKeys,
            orderTypeKeys,
            isPublished,
          } = doc.data();
          return {
            _id: menuItemId,
            categoryId,
            name,
            isNameHidden,
            urlS3,
            url,
            date,
            dateTimestamp,
            storeId,
            restaurantId,
            selectedExtraIngredients,
            selectedIngredients,
            selectedSizes,
            description,
            itemType,
            baseModifierId,
            selectedModifiers,
            orderFromKeys: orderFromKeys
              ? orderFromKeys
              : [MENU_ORDER_FROM.web, MENU_ORDER_FROM.pos],
            orderTypeKeys: orderTypeKeys
              ? orderTypeKeys
              : [
                  MENU_ORDER_TYPE.walkin,
                  MENU_ORDER_TYPE.pickup,
                  MENU_ORDER_TYPE.delivery,
                  MENU_ORDER_TYPE.dinein,
                ],
            isPublished,
          };
        });
        resolve(data);
      });
  });
}

export async function getUpdatedDataFromFile(key, storeId, url, isPopularDisabled = false) {
  const bucketName = process.env.REACT_APP_ENV === 'production' 
    ? 'store-menu-hashes-prod' 
    : 'store-menu-hashes';
  const commonCloudUrlPrefix = `https://storage.googleapis.com/${bucketName}`;

  try { 
    // Extract and encode parts of the URL
    const [urlPrefix, ...urlSuffixParts] = url.split('/');
    const urlSuffix = encodeURIComponent(urlSuffixParts.join('/'));
    const fullUrl = `${commonCloudUrlPrefix}/${urlPrefix}/${urlSuffix}`;

    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch ${key}. Status: ${response.status}`);
    }

    const responseData = await response.json();

    return responseData?.[key] || null;
  } catch (err) {

    const fetchFallbacks = {
      populars: async () => await getPopularsPromise(storeId, isPopularDisabled),
      categories: async () => await getCategoriesPromise(storeId),
      menuItems: async () => await getMenuItemsPromise(storeId),
      specials: async () => await getAllSpecialsPromise(storeId),
      reviewStats: async () => await getReviewStatsPromise(storeId),
      reviewStatsByRating: async () => await getReviewStatsByRatingPromise(storeId),
      vouchers: async () => await getAutoVouchersPromise(storeId),
      shifts: async () => await getAllShiftsPromise(storeId),
      suburbs: async () => await getAllSuburbsPromise(storeId),
      creditCardProcessingFees: async () => await getCreditCardsPromise(storeId),
      offers: async () => await getStoreOffersPromise(storeId),
      ingredientCategories: async () => await getIngredientCategoriesPromise(storeId),
    };

    return fetchFallbacks[key] ? await fetchFallbacks[key]() : null;
  }
}


export function getUpdatedStoreMenuUrls(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('StoreMenuHashes').doc(storeId)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.data();
        if(!data || Object.keys(data).length === 0) return resolve({})
        resolve(data);
      });
  });
}

export function getUpdatedRestaurantMenuUrls(restaurantId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('StoreMenuHashes').doc(restaurantId)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.data();
        if(!data || Object.keys(data).length === 0) return resolve({})
        resolve(data);
      });
  });
}

export function watchStoreMenuHashes(storeId, getState) {
  return async (dispatch) => {
    _firestore
      .collection('StoreMenuHashes')
      .where('storeId', '==', storeId)
      .onSnapshot(async (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          _id: doc.id,
          ...doc.data(),
        }));

        if (data.length === 0) return;

        const currentData = data[0] || {};
        const storeMenuHashes = getState().homeReducer?.storeMenuHashes || {};

        const isInitialLoad = Object.keys(storeMenuHashes).length === 0;

        let dataPayload = {};
        let urlPayload = {};
        let changesDetected = false;

        const processUpdate = async (key, urlKey, stateKey) => {
          if (currentData[urlKey] !== storeMenuHashes[stateKey]) {
            urlPayload[key] = currentData[urlKey];
            return await getUpdatedDataFromFile(
              key,
              storeId,
              currentData[urlKey]
            );
          }
          return null;
        };

        if (isInitialLoad) {
          ['categories', 'menuItems', 'specials', 'populars'].forEach((key) => {
            urlPayload[key] = currentData[`${key}StorageUrl`];
          });
          dispatch({
            type: ActionTypes.RefreshedUrls,
            data: urlPayload,
          });
          return;
        }

        const categories = await processUpdate(
          'categories',
          'categoriesStorageUrl',
          'categories'
        );
        const menuItems = await processUpdate(
          'menuItems',
          'menuItemsStorageUrl',
          'menuItems'
        );
        const specials = await processUpdate(
          'specials',
          'specialsStorageUrl',
          'specials'
        );
        const populars = await processUpdate(
          'populars',
          'popularsStorageUrl',
          'populars'
        );

        changesDetected = Object.keys(urlPayload).length > 0;

        if (changesDetected) {
          dispatch({
            type: ActionTypes.RefreshedUrls,
            data: urlPayload,
          });

          const state = getState().homeReducer;
          const currentMenuItems = menuItems || state?.allMenuItems;
          const currentPopulars = populars || state?.allPopulars;
          const menuItemSchedules = state?.menuItemSchedules;

          const { menuItems: updatedMenuItems, populars: updatedPopulars } =
            updateMenuItems(
              currentMenuItems,
              currentPopulars,
              menuItemSchedules
            );

          dataPayload = {
            menuItems: updatedMenuItems,
            allMenuItems: currentMenuItems,
            populars: updatedPopulars,
            allPopulars: currentPopulars,
          };

          if (categories) dataPayload['categories'] = categories;
          if (specials) dataPayload['allSpecials'] = specials;

          dispatch({
            type: ActionTypes.RefreshedData,
            data: dataPayload,
          });
        }
      });
  };
}

export function getStoreInfoPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('Stores')
      .doc(storeId)
      .get()
      .then((doc) => {
        resolve({ _id: doc.id, ...doc.data() });
      });
  });
}

export function getSoldOutMenuItemsPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('SoldOutMenuItems')
      .where('storeId', '==', storeId)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          return { _id: doc.id, ...doc.data() };
        });
        resolve(data);
      });
  });
}

export function getSoldOutMenuItems(storeId) {
  return (dispatch) => {
    _firestore
      .collection('SoldOutMenuItems')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
          return { _id: doc.id, ...doc.data() };
        });
        dispatch(updateSoldOutMenuItems(data));
      });
  };
}

export function getOrderSetupPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('OrderSetup')
      .where('storeId', '==', storeId)
      .get()
      .then((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (typeof data.autoDetectSpecialsWeb === 'undefined') {
            data.autoDetectSpecialsWeb = true;
          }
          dataArray.push({ _id: doc.id, ...data });
        });
        resolve(dataArray[0]);
      });
  });
}

export function getOrderSetup(storeId) {
  return (dispatch) => {
    _firestore
      .collection('OrderSetup')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        const dataArray = querySnapshot?.docs?.map((doc) => {
          const data = doc.data();
          return { _id: doc.id, ...data };
        });
        const orderSetup = dataArray?.[0] || {};
        if (typeof orderSetup?.autoDetectSpecialsWeb === 'undefined') {
          orderSetup.autoDetectSpecialsWeb = true;
        }
        dispatch(updateOrderSetup(orderSetup));
      });
  };
}

export function getCreditCardsPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('CreditCardProcessingFees')
      .where('storeId', '==', storeId)
      .where('merchantCode', '==', 300)
      .get()
      .then(resolveParseMenu(resolve));
  });
}

export function loadCurrentOrderMethod(storeConfig, orderObj) {
  orderObj = { ...orderObj, stage: ORDER_STAGE_TYPES.NONE };
  try {
    if (window.localStorage) {
      let obj = localStorage.get('currentOrder');
      if (obj) {
        var start_date = moment(obj.date, 'YYYY-MM-DD HH:mm');
        var end_date = moment(
          moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
          'YYYY-MM-DD HH:mm'
        );
        var duration = end_date.diff(start_date, 'minutes') || 0;
        if (Number(duration <= 60)) {
          obj.stage = ORDER_STAGE_TYPES.NONE;
          if (!obj?.menuItems?.length) {
            obj.orderType = orderObj?.orderType || obj?.orderType;
          }
          if (obj.orderType !== MENU_ORDER_TYPE.dinein) {
            delete obj.tableId;
            delete obj.tableNumber;
            delete obj.floorId;
          }
          return obj;
        } else {
          localStorage.set('currentOrder', orderObj);
          return orderObj;
        }
      } else {
        localStorage.set('currentOrder', orderObj);
        return orderObj;
      }
    } else {
      return orderObj;
    }
  } catch (err) {
    return orderObj;
  }
}

export function loadUserSelectedVoucherItems(isVoucherExists) {
  try {
    if (window.localStorage) {
      let obj = localStorage.get('userSelectedVoucherItems');
      return isVoucherExists ? obj : [];
    }
  } catch (e) {
    return [];
  }
}

export function getItemSizesPromise(restaurantId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('ItemSizes')
      .where('restaurantId', '==', restaurantId)
      .where('isDeleted', '==', false)
      .get()
      .then(resolveParseMenu(resolve));
  });
}

export function getStoreOffersPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('Offers')
      .where('stores', 'array-contains', storeId)
      .where('isDeleted', '==', false)
      .get()
      .then(resolveParseMenu(resolve));
  });
}

export function getPublicHolidaysPromise(storeConfig) {
  let days = [];
  let today = getCurrentMomentDate(storeConfig?.timeZone);
  days.push(today?.format('YYYY-MM-DD'));
  for (var i = 1; i < 10; i++) {
    days.push(today?.add(1, 'days').format('YYYY-MM-DD'));
  }
  return Promise.all(
    days.map((m) => {
      return _firestore
        .collection('PublicHolidays')
        .where('date', '==', m)
        .where(
          'countryIdentifier',
          '==',
          storeConfig.countryIdentifier.toUpperCase()
        )
        .where('stateList', 'array-contains-any', [
          storeConfig.stateIdentifier.toUpperCase(),
          storeConfig.storeId,
          'ALL',
        ])
        .get()
        .then((querySnapshot) => {
          return _firestore
            .collection('PublicHolidayOptouts')
            .where('date', '==', m)
            .where('storeId', '==', storeConfig.storeId)
            .get()
            .then((querySnapshot1) => {
              if (
                querySnapshot.docs.length > 0 &&
                querySnapshot1.docs.length <= 0
              ) {
                return m;
              }
            });
        });
    })
  );
}

export function getAutoVouchersPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('Vouchers')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true)
      .where('isAutoApply', '==', true)
      .get()
      .then(resolveParseMenu(resolve));
  });
}

import { _firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getReviewStatsPromise,
  getReviewStatsByRatingPromise,
} from './methods';
import {
  getHalfHalfPromise,
  getCreditCardsPromise,
  getStoreOffersPromise,
  getPublicHolidaysPromise,
  getAutoVouchersPromise,
  getUpdatedStoreMenuUrls,
  getUpdatedDataFromFile,
} from '../Home/methods';

export function setState(payload) {
  return {
    type: `@layout/setState`,
    payload,
  };
}

export function initLayout(storeConfig) {
  const { storeId } = storeConfig;

  return async function (dispatch) {
    const offers = window.Offers || [];
    dispatch({
      type: ActionTypes.InitializeLayout,
      payload: { offers },
    });
    const {
      storeReviewStatsStorageUrl, // reviewStatsUrl
      reviewStatsByRatingStorageUrl, // reviewStatsByRatingUrl
      vouchersStorageUrl, // vouchersUrl
      creditCardProcessingFeesStorageUrl, // creditCardsUrl
      offersStorageUrl, // offersUrl
    } = await getUpdatedStoreMenuUrls(storeId);

    const [
      reviewStats,
      reviewStatsByRating,
      autoVouchers,
      creditCards,
    ] = await Promise.all([
      getUpdatedDataFromFile('storeReviewStats', storeId, storeReviewStatsStorageUrl),
      getUpdatedDataFromFile('reviewStatsByRating', storeId, reviewStatsByRatingStorageUrl),
      getUpdatedDataFromFile('vouchers', storeId, vouchersStorageUrl),
      getUpdatedDataFromFile('creditCards', storeId, creditCardProcessingFeesStorageUrl),
    ]);

    const [
      halfHalfSetup,
      publicHolidays,
    ] = await Promise.all([
      getHalfHalfPromise(storeId),
      getPublicHolidaysPromise(storeConfig),
    ]);

    if (!offers?.length) {
      const storeOffers = await getUpdatedDataFromFile('offers', storeId, offersStorageUrl);
      dispatch({
        type: ActionTypes.InitializeLayout,
        payload: { offers: storeOffers },
      });
    }

    const payload = {
      reviewStats,
      reviewStatsByRating,
      halfHalfSetup,
      creditCards,
      publicHolidays,
      autoVouchers,
    };
    dispatch({
      type: ActionTypes.InitializeLayout,
      payload,
    });
  };
}

export function recordPhoneClick(obj) {
  return () => {
    _firestore
      .collection('/PhoneNumberClicked')
      .add(obj)
      .then(() => {});
  };
}
